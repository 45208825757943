import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"


const ContactMe = () => {
  return (
    <PageLayout>
      <SEO
        title="Get a Offset or Letterpress Quote from Pressroom Alchemy"
        ogDescription="Get a personalized quote for custom printing in Atlanta. Fill out our form, and our experts will create a tailored solution for your project."
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>
              Contacting Pressroom Alchemy
            </h1>
            <p>Thank you for considering us for your printing needs in Atlanta! We're thrilled that you've chosen to connect with us. Our team of experts is eager to assist you in bringing your vision to life through custom printing, offset and letterpress printing, foil stamping, embossing/debossing, and custom binding. To provide you with the most accurate and personalized quote, please fill out the form below. We look forward to hearing about your project and will get back to you promptly with a tailored solution that matches your requirements and exceeds your expectations. Thank you for choosing us.</p>
            <h2>Get a quote</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default ContactMe
